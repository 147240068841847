<template>
<v-card elevation="0" class="title">
    <v-tabs  centered height="35" @change=changeTab($event)   v-model="currentItem" >
        <v-tab  v-for="item in items" :key="item" :href="'#tab-' + item"  @change="clickInTab(item)"> 
            {{ item }}
            <!-- <v-badge
                v-if="hasObservation(item) && item == 'Condiciones'"
                color="error"
                dot
            >
                <v-icon icon="mdi-newspaper-variant-outline"></v-icon>
            </v-badge> -->
        </v-tab>
    </v-tabs>
    <v-tabs-items 
        v-model="currentItem" 
        style="padding: 10 10px 10px 0px;"
        >
        <v-tab-item v-for="item in items" :key="item" :value="'tab-' + item">
            <v-card flat tile elevation="0">
                <v-tabs   right hide-slider="" v-if='item=="Descripción"' vertical @change=changeTab($event) v-model="subCurrent">
                    <v-tab v-for="item in itemsDescription"  :href="'#tab-' + item">
                        {{ item }}
                        <v-badge
                            v-if="hasObservation(item)"
                            color="error"
                            dot
                        >
                            <v-icon icon="mdi-newspaper-variant-outline"></v-icon>
                        </v-badge>
                    </v-tab>
                    <v-tabs-items v-model="subCurrent">
                        <v-tab-item v-for="itemDes in itemsDescription" :key="itemDes" :value="'tab-' + itemDes">
                            <v-card elevation="0" flat tile>
                                <s-crud 
                                    class="" 
                                    v-if='itemDes=="Funciones"'  
                                    :filter=filterFuntions 
                                    height="145" 
                                    :config=config 
                                    :title=itemDes 
                                    @save=saveFunction($event) 
                                    add 
                                    edit 
                                    remove
                                    ref="scrudfunciones"
                                >
                                <!-- :refreshTable="refreshTableFunciones" -->
                                    <!-- <template v-slot:options>
                                        <v-tooltip bottom="">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    text 
                                                    @click="openDialogObservations(1, itemDes)"
                                                    small>
                                                    <i class="fas fa-clipboard"></i>
                                                </v-btn>
                                            </template>
                                            <span>Observaciones</span>
                                        </v-tooltip>
                                    </template> -->

                                    <template scope="props">
                                        <v-col>
                                            <v-row justify="center">
                                                <v-col class="s-col-form" lg=7>
                                                    <s-textarea hide-details outlined height="50" autofocus label="Descripción" v-model=props.item.PfnDescription>
                                                    </s-textarea>
                                                </v-col>
                                                <v-col class="s-col-form" style="margin-top:15px;" lg=2>
                                                    <s-switch label="Es Principal" v-model=props.item.PfnIsPrimary></s-switch>
                                                </v-col>
                                                <v-col>
                                                    <s-select-definition 
                                                        v-if="$fun.isAdmin()"
                                                        :def="1449" 
                                                        v-model=props.item.PfnStatus
                                                        label="Estado de Aprobacion">

                                                    </s-select-definition>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </template>

                                    <template v-slot:PfnIsPrimary="{ row }">
                                        <v-chip style="margin:0px" x-small :color="row.PfnIsPrimary==1?'primary':'info'">
                                            {{row.PfnIsPrimary==1?'Principal':'Secundario'}}
                                        </v-chip>
                                    </template>

                                    <template v-slot:action="{ row }">
                                        <v-btn
                                            text 
                                            @click="openDialogObservations(1, itemDes, row, row.PfnID, row.PfnDescription)"
                                            small
                                            :color="row.HasObservation > 0 ? 'red' : 'black'"
                                        >
                                            <i class="fas fa-clipboard"></i>
                                        </v-btn>
                                    </template>
                                </s-crud>
                                <s-crud
                                    v-if='itemDes=="Reportes"' 
                                    :filter=filterReport add edit remove height="145" 
                                    :config=configReport :title=itemDes @save=saveReport($event)
                                    ref="scrudreportes"
                                >

                                    <template scope="props">
                                        <v-col>
                                            <v-row>
                                                <v-col lg=6 class="s-col-form">
                                                    <s-text label="Description" autofocus v-model=props.item.RppDescription></s-text>
                                                </v-col>

                                                <v-col lg=4 class="s-col-form">
                                                    <s-text label="Usuarios" v-model="props.item.RppUsers"></s-text>
                                                </v-col>
                                                <!-- <v-col lg=2 class="s-col-form">
                                                    <s-text label="Frecuencia" v-model="props.item.PstFrequency"></s-text>
                                                </v-col> -->
                                                <v-col lg=2 class="s-col-form">
                                                    <s-select-definition add :def=1127 v-model=props.item.RppFrequency label="Frecuencia"></s-select-definition>
                                                </v-col>
                                                <v-col lg=4 class="s-col-form">
                                                    <s-select-definition 
                                                        v-if="$fun.isAdmin()"
                                                        :def="1449" 
                                                        v-model=props.item.RppStatus
                                                        label="Estado de Aprobacion">

                                                    </s-select-definition>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </template>
                                    
                                    <template v-slot:action="{ row }">
                                        <v-btn
                                            text 
                                            @click="openDialogObservations(2, itemDes, row, row.RppID, row.RppDescription)"
                                            small
                                            :color="row.HasObservation > 0 ? 'red' : 'black'"
                                        >
                                            <i class="fas fa-clipboard"></i>
                                        </v-btn>
                                    </template>

                                </s-crud>

                                <s-crud v-if='itemDes=="Indicadores"' :filter=filterKPI height="145" :config=configKPI :title=itemDes @save=saveKPI($event) add edit remove ref="scrudindicadores">

                                    <template scope=props>
                                        <v-col>
                                            <v-row>
                                                <v-col cols="12" lg=6 class="s-col-form">
                                                    <s-text label="Descripción" autofocus v-model=props.item.IndDescription></s-text>
                                                </v-col>
                                                <v-col cols="12" lg=3 class="s-col-form">
                                                    <s-select-definition add :def=1125 v-model=props.item.IndUnitMeasurement label="Unidad de Medición"></s-select-definition>
                                                </v-col>
                                                 <v-col cols="12" lg=3 class="s-col-form">
                                                    <s-select-definition add :def=1127 v-model=props.item.IndPeriodicity label="Perioricidad"></s-select-definition>
                                                </v-col>
                                                <!-- <v-col lg=2 class="s-col-form">
                                                    <s-select-definition :def=1124 v-model=props.item.IndOrientation label="Orientación"></s-select-definition>
                                                </v-col> -->
                                                <v-col cols="12" lg=8 class="s-col-form">
                                                    <s-text label="Meta" v-model="props.item.IndGoal"></s-text>
                                                </v-col>
                                                <v-col cols="12" lg=4 class="s-col-form">
                                                    <s-select-definition 
                                                        v-if="$fun.isAdmin()"
                                                        :def="1449" 
                                                        v-model=props.item.IndStatus
                                                        label="Estado de Aprobacion">

                                                    </s-select-definition>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </template>

                                    <template v-slot:action="{ row }">
                                        <v-btn
                                            text 
                                            @click="openDialogObservations(3, itemDes, row, row.IndID, row.IndDescription)"
                                            small
                                            :color="row.HasObservation > 0 ? 'red' : 'black'"
                                        >
                                            <i class="fas fa-clipboard"></i>
                                        </v-btn>
                                    </template>

                                </s-crud>

                                </v-card> </v-tab-item> </v-tabs-items> </v-tabs> 
                                <v-tabs hide-slider="" :centered=false v-if='item=="Perfil"' vertical="" v-model="currentPerfil"  @change=changeTab($event)>
                                    <v-tab  v-for="itemPer in itemsPerfil" :key="itemPer" :href="'#tab-' + itemPer"> 
                                        {{ itemPer }}
                                       <!--  <v-badge
                                            v-if="hasObservation(item)"
                                            color="error"
                                            dot
                                        >
                                            <v-icon icon="mdi-newspaper-variant-outline"></v-icon>
                                        </v-badge> -->
                                    </v-tab>
                                    <v-tabs-items v-model="currentPerfil">
                                        <v-tab-item v-for="itemPer in itemsPerfil" :key="itemPer" :value="'tab-' + itemPer" class="background-color:red;">
                                            <v-card elevation="0" flat tile>
                                                <s-crud v-if='itemPer=="Formación_Académica"' height="145" :config=configAcademic :title=itemPer @save=saveAcademic($event) :filter=filterAcademic add edit remove ref="scrudconfiguracionacademica">

                                                    <template scope="props">
                                                        <v-col>
                                                            <v-row >
                                                                <v-col class="s-col-form" sm=6 xs=12>
                                                                    <s-select-definition :def=1084 v-model=props.item.PprQualificationValue label="Tipo"></s-select-definition>
                                                                </v-col>
                                                                <v-col class="s-col-form" sm=6 xs=12>
                                                                    <s-select-definition :def=1523 v-model=props.item.PprMinMax label="Formación"></s-select-definition>
                                                                </v-col>
                                                                <v-col class="s-col-form" sm=12 xs=12>
                                                                    <s-text v-model=props.item.PprDescription autofocus label="Descripción "></s-text>
                                                                </v-col>
                                                                <!-- Profile PprStatus -->
                                                                <v-col lg=4 class="s-col-form">
                                                                    <s-select-definition 
                                                                        v-if="$fun.isAdmin()"
                                                                        :def="1449" 
                                                                        v-model=props.item.PprStatus
                                                                        label="Estado de Aprobacion">

                                                                    </s-select-definition>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </template>
                                                    <template v-slot:action="{ row }">
                                                        <v-btn
                                                            text 
                                                            @click="openDialogObservations(4, itemPer, row, row.PprID, row.PprDescription)"
                                                            small
                                                            :color="row.HasObservation > 0 ? 'red' : 'black'"
                                                        >
                                                            <i class="fas fa-clipboard"></i>
                                                        </v-btn>
                                                    </template>

                                                </s-crud>
                                                <s-crud v-if='itemPer=="Conocimientos"' height="145" :config=configKnowledge :title=itemPer @save=savePerfilKnowledge($event) :filter=filterKnowledge add edit remove ref="scrudconocimientos">

                                                    <template scope="props">
                                                        <v-col>
                                                            <v-row >
                                                                <v-col class="s-col-form" lg=4 md=4 sm=6 xs=12>
                                                                    <s-select-definition :def=1085 v-model=props.item.PprQualificationValue label="Tipo"></s-select-definition>
                                                                </v-col>
                                                                <v-col class="s-col-form" lg=4 md=4 sm=6 xs=12>
                                                                    <s-text v-model=props.item.PprDescription autofocus label="Descripción"></s-text>
                                                                </v-col>
                                                                <v-col class="s-col-form" lg=4 md=4 sm=6 xs=12>
                                                                    <s-select dense outlined hide-details :items=itemsSelectKnowledge v-model=props.item.PprQualificationDescription label="Nivel"></s-select>
                                                                </v-col>
                                                                <v-col lg=4 class="s-col-form">
                                                                    <s-select-definition 
                                                                        v-if="$fun.isAdmin()"
                                                                        :def="1449" 
                                                                        v-model=props.item.PprStatus
                                                                        label="Estado de Aprobacion">

                                                                    </s-select-definition>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </template>

                                                    <template v-slot:action="{ row }">
                                                        <v-btn
                                                            text 
                                                            @click="openDialogObservations(5, itemPer, row, row.PprID, row.PprDescription)"
                                                            small
                                                            :color="row.HasObservation > 0 ? 'red' : 'black'"
                                                        >
                                                            <i class="fas fa-clipboard"></i>
                                                        </v-btn>
                                                    </template>

                                                </s-crud>
                                                <s-crud v-if='itemPer=="Competencias"' :filter=filterCompetition height="145" :config=configCompetition :title=itemPer @save=savePerfilCompetition($event) add edit remove ref="scrudcompetencias">
                                                    
                                                    <template scope="props">
                                                        <v-col>
                                                            <v-row >
                                                                <v-col class="s-col-form" lg=4 md=4 sm=6 xs=12>
                                                                    <s-select-definition :def=1086 v-model=props.item.PprQualificationValue label="Tipo"></s-select-definition>
                                                                </v-col>

                                                                <v-col class="s-col-form" lg=4 md=4 sm=6 xs=12 v-show='props.item.PprQualificationValue==1'>
                                                                    <s-select-definition :def=1120 v-model=props.item.PprDescription label="Descripción" id="DedDescription"></s-select-definition>
                                                                </v-col>
                                                                <v-col class="s-col-form" lg=4 md=4 sm=6 xs=12 v-show='props.item.PprQualificationValue==3'>
                                                                    <s-select-definition :def=1121 v-model=props.item.PprDescription label="Descripción" id="DedDescription"></s-select-definition>
                                                                </v-col>
                                                                <v-col class="s-col-form" lg=4 md=4 sm=6 xs=12 v-show='props.item.PprQualificationValue==2'>
                                                                    <s-select-definition :def=1122 v-model=props.item.PprDescription label="Descripción" id="DedDescription"></s-select-definition>
                                                                </v-col>
                                                                <v-col class="s-col-form" lg=4 md=4 sm=6 xs=12>
                                                                    <s-select dense outlined hide-details :items=itemsSelectCompetition v-model=props.item.PprQualificationDescription label="Nivel"></s-select>
                                                                </v-col>
                                                                <v-col lg=4 class="s-col-form">
                                                                    <s-select-definition 
                                                                        v-if="$fun.isAdmin()"
                                                                        :def="1449" 
                                                                        v-model=props.item.PprStatus
                                                                        label="Estado de Aprobacion">

                                                                    </s-select-definition>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </template> 

                                                    <template v-slot:action="{ row }">
                                                        <v-btn
                                                            text 
                                                            @click="openDialogObservations(6, itemPer, row, row.PprID, row.PprDescription)"
                                                            small
                                                            :color="row.HasObservation > 0 ? 'red' : 'black'"
                                                        >
                                                            <i class="fas fa-clipboard"></i>
                                                        </v-btn>
                                                    </template>

                                                </s-crud>
                                                <s-crud v-if='itemPer=="Experiencia"' :filter=filterExperience height="145" :config=configExperience :title=itemPer add edit remove @save=savePerfilExperience($event) ref="scrudexperiencia">

                                                    <template scope="props">
                                                        <v-col>
                                                            <v-row >
                                                                <v-col class="s-col-form" lg=8 md=8 sm=6 xs=12>
                                                                    <s-text autofocus v-model=props.item.PprDescription label="Descripción"></s-text>
                                                                </v-col>
                                                                <v-col class="s-col-form" lg=4 md=4 sm=6 xs=12>
                                                                    <s-text v-model=props.item.PprQualificationDescription label="Años"></s-text>
                                                                </v-col>
                                                                <v-col lg=4 class="s-col-form">
                                                                    <s-select-definition 
                                                                        v-if="$fun.isAdmin()"
                                                                        :def="1449" 
                                                                        v-model=props.item.PprStatus
                                                                        label="Estado de Aprobacion">

                                                                    </s-select-definition>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </template>

                                                    <template v-slot:action="{ row }">
                                                        <v-btn
                                                            text 
                                                            @click="openDialogObservations(7, itemPer, row, row.PprID, row.PprDescription)"
                                                            small
                                                            :color="row.HasObservation > 0 ? 'red' : 'black'"
                                                        >
                                                            <i class="fas fa-clipboard"></i>
                                                        </v-btn>
                                                    </template>

                                                </s-crud>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs-items>
                </v-tabs>

                <div v-if='item=="Condiciones"'>
                    
                    <s-crud :filter=filterCondition height="145" :config=configCondition :title=item @save=saveCondition($event) add edit remove ref="scrudcondiciones">
                        

                        <template scope=props>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <s-select-definition :def=1026 label="Condición" v-model=props.item.TypeWorkingCondition></s-select-definition>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <s-text label="Descripción" autofocus v-model=props.item.PwcDescription></s-text>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col lg=4 >
                                        <s-select-definition 
                                            v-if="$fun.isAdmin()"
                                            :def="1449" 
                                            v-model=props.item.PwcStatus
                                            label="Estado de Aprobacion">

                                        </s-select-definition>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </template>

                        <template v-slot:action="{ row }">
                            <v-btn
                                text 
                                @click="openDialogObservations(8, item, row, row.PwcID, row.PwcDescription)"
                                small
                                :color="row.HasObservation > 0 ? 'red' : 'black'"
                            >
                                <!-- {{row}} --><i class="fas fa-clipboard"></i>
                            </v-btn>
                        </template>

                    </s-crud>
                </div>

                <div v-if='item=="Responsabilidades"'>
                    <v-container>
                        <s-crud
                            :config="configResponsability"
                            title="Responsabilidades"
                            :filter="filterResponsability"
                            search-input
                            :add="$fun.isAdmin()"
                            :edit="$fun.isAdmin()"
                            :remove="$fun.isAdmin()"
                            ref="scrudResponsabilidades"
                            @save="saveResponsability($event)"
                        >
                            <template v-slot="props">
                                <v-container>
                                    <v-row>
                                        <v-col lg="4">
                                            <s-select-definition
                                            :def="1522"
                                            label="Responsabilidad" 
                                            v-model="props.item.RpdCategory"
                                            v-if="TypeAsignResponsability"
                                            :dgr="78"
                                            @change="loadResponsability(props.item.RpdCategory)"
                                            />
                                        </v-col>
                                        <v-col lg="4">
                                            <s-select-definition
                                                label="Equipo o Material"
                                                :def="1520"
                                                :dgr="TypeAsignResponsability"
                                                v-model="props.item.RpdEquipment"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                        </s-crud>
                    </v-container>
                </div>
            </v-card>
        </v-tab-item>
    </v-tabs-items>
    
    <!-- modal para agregar una observación sobre algo del puesto -->
    <v-dialog
        v-model="dialogObservation"
        width="1000"
        persistent
    >
        <s-select-definition
            :def="1408"
            @getDataInitial="getItemsTypeAction($event)"
        ></s-select-definition>
        <v-card dense>
            <s-toolbar
                :label="nameTypeObservation"
                @save="saveObservation()"
                save
                close
                @close="closeToolbar()"
            ></s-toolbar>
            <v-container>
                <v-row>
                    <v-col>
                        <v-label>
                            <h3>
                                <strong>{{itemDetailSelected.description}}</strong>
                            </h3>
                        </v-label>
                    </v-col>
                </v-row>
                <!-- <v-row>
                    <v-col cols="2">
                        <v-radio-group 
                            column
                            v-model="TypeAction"
                            @change="changeTypeAction($event)"
                        >
                            <v-radio
                                v-for="item in itemsTypeAction"
                                :key="item.DedValue"
                                :value="item.DedValue"
                            >
                                <template v-slot:label>
                                    <div><strong :class="item.DedHelper+'--text'">{{item.DedDescription}}</strong></div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="10">
                        <s-textarea
                            label="Observaciones"
                            :height="100"
                            v-model="PsoDescription"
                        ></s-textarea>
                    </v-col>
                    
                </v-row> -->
                <v-row>
                    <v-col>
                        <v-data-table
                            :headers="headersObservation"
                            :items="itemsObservations"
                            v-model="itemsObservations2"
                        >
                            <template v-slot:item.PsoDescription="{ item }">
                                <v-chip 
                                    x-small
                                    :color="item.TypeActionColor"
                                >
                                    {{item.TypeActionName}}
                                </v-chip>
                                {{'  ' + item.PsoDescription}}
                            </template>

                            <template v-slot:item.PsoStatus="{ item }">
                                <v-chip 
                                    :color="item.PsoStatusColor"
                                    x-small
                                >
                                    {{item.PsoStatusName}}
                                </v-chip>
                            </template>

                            <template v-slot:item.DateAction="{ item }">
                                    {{ item.DateAction ? moment(item.DateAction).format($const.FormatDateTimeView) : "" }}                                 
                            </template>
                            
                            <template v-slot:item.SecCreate="{ item }">                               
                                 {{ item.SecCreate ? moment(item.SecCreate).format($const.FormatDateView) : "" }}                         
                            </template>
                            <template v-slot:item.Comment=" { item }">
                                <s-text v-if="item.Line == 1" v-model="item.Comment"></s-text>
                            </template>
                            <!-- <template v-slot:item.PsoAction="{ item }">
                                <v-btn
                                    v-if="$fun.isAdmin()"
                                    :disabled="item.PsoStatus != 1"
                                    x-small
                                    fab
                                    class="mr-2 success"
                                    @click="approveObservation(item, 2)"
                                
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="$fun.isAdmin()"
                                    :disabled="item.PsoStatus != 1"
                                    x-small
                                    fab
                                    color="error"
                                    @click="approveObservation(item, 4)"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="!$fun.isAdmin()"
                                    :disabled="item.PsoStatus != 4"
                                    x-small
                                    fab
                                    color="warning"
                                    @click="openDialogCorrection(item)"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template> -->

                            <template v-slot:item.Reason="{ item }"  v-if="$fun.isAdmin()">                               
                                <s-text v-if="item.PsoStatus == 1"   v-model="item.Reason" />   
                                {{ item.PsoStatus == 1 ? "" : item.Reason  }}                     
                            </template>

                        </v-data-table>
                    </v-col>
                    
                </v-row>

            </v-container>
        </v-card>
    </v-dialog>
    <!-- fin de modal para agregar observacion sobre puesto -->

    <!-- ingresar la correcion -->
    <v-dialog
        v-if="dialogCorrection"
        v-model="dialogCorrection"
        width="600"
        height="800"
    >
        <v-card dense outlined>
            <s-toolbar
                label="Corrección"
                @save="saveObservation()"
                save
                close
                @close="closeDialogCorrection()"
            ></s-toolbar>
            <v-container>
                <v-row>
                    <v-col>
                        <s-textarea
                            label="Correción"
                            :height="100"
                            v-model="PsoDescriptionCorrection"
                        ></s-textarea>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</v-card>
</template>

<script>
import Service from './../../services/General/FunctionPositionService'
import ServiceReport from './../../services/General/PositionReport'
import ServiceAcademic from './../../services/General/PositionAcademic'
import ServiceCompetition from './../../services/General/PositionCompetition'
import ServiceKnowledge from './../../services/General/PositionKnowledge'
import ServiceExperience from './../../services/General/PositionExperience'
import ServiceCondition from './../../services/General/PositionCondition'
import ServiceBackground from './../../services/General/PositionBackground'
import ServiceIndicator from '@/services/General/PositionIndicator'
import ServicePostionObservation from '@/services/General/GenPositionObservationService'
import SGenPositionRecord from '@/services/General/GenPositionRecord'
import ServiceResponsability from '@/services/General/ServiceResponsability'
export default {
    name: "PositionDetail",
    components: {},
    props: {
        parentID: { type: Number, default: 0 },
    },
    data: () => ({
        TypeAsignResponsability: 79,
        TypeAction : 0,
        itemsTypeAction : [],
        itemDetailSelected : {},
        DocEntry : 0,
        dialogCorrection : false,
        PsoIDCorrection : null,
        IsCorrection : false,
        PsoDescriptionCorrection : "",
        typeObservationArray: ['Funciones', 'Reportes', 'Indicadores', 'Formación_Académica', 'Conocimientos', 'Competencias', 'Experiencia', 'Condiciones'],
        headersObservation: [
            {text: 'ID', value: 'PsoID'},
            {text: 'Descripción', value: 'PsoDescription'},
            {text: 'F. de Creacion', value: 'SecCreate'},
            {text: 'Usuario', value: 'UsrName'},
            {text: 'Comentario', value: 'Comment'},
            {text: 'Estado', value: 'PsoStatus'},
            // {text: 'Fecha C/R', value: 'DateAction'},
            // {text: 'Acción', value: 'PsoAction'}, 
            // {text: 'Motivo de Rechazo', value: 'Reason'},
            // {text: 'Obs. Rechazada', value: 'PsoDescriptionRefused'},
        ],
        itemsObservations2: [],
        itemsObservations: [],
        PsoDescription: '',
        nameTypeObservation: '',
        typeObservation: 0,
        dialogObservation: false,
        tabSelected: '',
        itemsSelectKnowledge: [{ text: 'Básico', value: 'Básico' }, { text: 'Intermedio', value: 'Intermedio' }, { text: 'Avanzado', value: 'Avanzado' }],
        itemsSelectCompetition: [{ text: '1', value: '1' }, { text: '2', value: '2' }, { text: '3', value: '3' }, { text: '4', value: '4' }],
        subCurrent: 'tab-Descripción',
        filterFuntions: {},
        filterResponsability: {
            PstID: 0
        },
        filterReport: {},
        filter: {},
        filterCondition: {},
        filterBackground: {},
        filterKPI: {},
        filterExperience: {},
        filterCompetition: {},
        filterKnowledge: {},
        config: {
            model: {
                PfnDescription: "string",
                PfnIsPrimary: "",
                PfnID: "ID",
                PstID: "number",
                SecStatus: "booleam",
                PfnStatus: "status",
                action: "",
            },
            service: Service,
            headers: [
                { text: 'ID', value: 'PfnID' },
                { text: 'DESCRIPCIÓN', value: 'PfnDescription' },
                { text: '', value: 'PfnIsPrimary' },
                { text: 'Estado', value: 'PfnStatusDescription' },
                { text: 'Acción', value: 'action' },
            ],
        },
        configResponsability: {
            model: {
                RpdID: "ID",
            },
            service: ServiceResponsability,
            headers: [
                { text: 'ID', value: 'RpdID' },
                { text: 'Categoria', value: 'RpdCategoryName' },
                { text: 'Equipo o Material', value: 'RpdEquipmentName' },
            ],
        },
        configReport: {
            model: {
                RppUsers: "string",
                PstFrequency: "string",
                RppDescription: "string",
                RppID: "ID",
                SecStatus: "booleam",
                action: "",
            },
            service: ServiceReport,
            headers: [
                { text: 'ID', value: 'RppID' },
                { text: 'REPORTE / INFORME', value: 'RppDescription' },
                { text: 'USUARIOS', value: 'RppUsers' },
                { text: 'FRECUENCIA', value: 'RppFrequencyName' },                
                { text: 'Estado', value: 'RppStatusDescription' },
                { text: 'Acción', value: 'action' },
            ],
        },
        filterAcademic: {},
        configAcademic: {
            model: {
                TypeProfile: "int",
                PprID: "ID",
                SecStatus: "boleam",
                PprQualificationValue: "int",
                PprQualificationDescription: 'string',
                PprDescription: 'string',
                action: "",
            },
            service: ServiceAcademic,
            headers: [
                { text: 'ID', value: 'PprID' },
                { text: 'Tipo', value: 'TypeProfileText' },
                { text: 'Especialidad', value: 'PprDescription' },
                { text: 'Formación', value: 'PprMinMaxDescription' },
                { text: 'Estado', value: 'PprStatusDescription' },
                { text: 'Acción', value: 'action' },
            ],
        },

        configCompetition: {
            model: {
                MntName: "string",
                PprID: 'ID',
                SecStatus: "boleam",
                MntStatus: "status",
                MntValidityDate: "date",
                MntDescription: "string",
                MntAbbreviature: "string",
                action: "",
            },
            service: ServiceCompetition,
            headers: [
                { text: 'ID', value: 'PprID' },
                { text: 'Tipo', value: 'TypeProfileText' },
                { text: 'Descripción', value: 'PprDescription' },
                { text: 'Nivel', value: 'PprQualificationDescription' },
                { text: 'Estado', value: 'PprStatusDescription' },
                { text: 'Acción', value: 'action' },
            ],
        },
        configBackGround: {
            model: {
                PbgID: "ID",
                PstID: "int",
                SecStatus: "boleam",
                TypeBackground: "int",
                PbgDescription: "string",
                PbgPercentage: "int"
            },
            service: ServiceBackground,
            headers: [
                { text: 'ID', value: 'PbgID' },
                { text: 'Tipo', value: 'TypeBackgroundText' },
                { text: 'Descripción', value: 'PbgDescription' },
                { text: 'Estado', value: 'PbgStatus' },
                { text: 'Porcentaje', value: 'PbgPercentage' },
            ],
        },
        configCondition: {
            model: {
                PwcID: "ID",
                PstID: "int",
                PwcDescription: "string",
                SecStatus: "boleam",
                TypeWorkingCondition: "int",
                action: "",
            },
            service: ServiceCondition,
            headers: [
                { text: 'ID', value: 'PwcID' },
                { text: 'Tipo de Condición', value: 'TypeWorkingConditionText' },
                { text: 'Descripción', value: 'PwcDescription' },
                { text: 'Estado', value: 'PwcStatusDescription' },
                // { text: 'Acción', value: 'action' },

            ],
        },
        configKnowledge: {
            model: {
                MntName: "string",
                PprID: "ID",
                SecStatus: "boleam",
                MntStatus: "status",
                MntValidityDate: "date",
                MntDescription: "string",
                MntAbbreviature: "string",
                action: "",
            },
            service: ServiceKnowledge,
            headers: [
                { text: 'ID', value: 'PprID' },
                { text: 'Tipo', value: 'TypeProfileText' },
                { text: 'Descripción', value: 'PprDescription' },
                { text: 'Nivel', value: 'PprQualificationDescription' },
                { text: 'Estado', value: 'PprStatusDescription' },
                { text: 'Acción', value: 'action' },
            ],
        },
        configKPI: {
            model: {
                IndDescription: "string",
                IndID: "ID",
                SecStatus: "boleam",
                IndStatus: "status",
                IndOrientationText: "string",
                IndUnitMeasurementText: "string",
                action: "",
            },
            service: ServiceIndicator,
            headers: [
                { text: 'ID', value: 'IndID' },
                { text: 'DESCRIPCIÓN', value: 'IndDescription' },
                { text: 'UNIDAD DE MEDICIÓN', value: 'IndUnitMeasurementText' },
                { text: 'PERIODICIDAD', value: 'IndPeriodicityText' },
                { text: 'META', value: 'IndGoal' },
                { text: 'ESTADO', value: 'IndStatusDescription' },
                { text: 'ACCIÓN', value: 'action' },

            ],
        },
        configExperience: {
            model: {
                MntName: "string",
                MntID: "ID",
                SecStatus: "boleam",
                MntStatus: "status",
                MntValidityDate: "date",
                MntDescription: "string",
                MntAbbreviature: "string",
                action: "",
            },
            service: ServiceExperience,
            headers: [
                { text: 'ID', value: 'PprID' },
                { text: 'Descripción', value: 'PprDescription' },
                { text: 'Años', value: 'PprQualificationDescription' },
                { text: 'Estado', value: 'PprStatusDescription' },
                { text: 'Acción', value: 'action' },
            ],
        },

        currentItem: 'tab-Funciones',
        currentPerfil: 'tab-perfil',
        items: ['Descripción', 'Perfil', 'Condiciones', 'Responsabilidades' ],
        itemsDescription: ['Funciones', 'Reportes', 'Indicadores'],
        itemsPerfil: ['Formación_Académica', 'Conocimientos', 'Competencias', 'Experiencia'],
    }),


    methods: {
        saveResponsability(item){
            item.UsrCreateID = this.$fun.getUserID();
            item.PstID = this.filterResponsability.PstID;

            console.log('item ',item);
            item.save();
        },
        loadResponsability(item){
            console.log("eventooooo",item);
            if(item == 1){
                this.TypeAsignResponsability = 79;
            }else if(item == 2){
                this.TypeAsignResponsability = 80;
            }
        },
        changeTypeAction(typeaction)
        {
            if(typeaction == 1)
            {
                this.itemDetailSelected.description = '';
                this.DocEntry = null;
            }
            else{
                this.itemDetailSelected.description = this.itemDetailSelected.descriptionBack;
                this.DocEntry = this.itemDetailSelected.DocEntry;
            }
        },

        getItemsTypeAction(items)
        {
            this.itemsTypeAction = items;
            console.log('itemsitemsitemsitemsitemsitemsitemsitemsitemsitems ', items);
        },

        openDialogCorrection(item)
        {
            this.dialogCorrection = true;
            this.PsoIDCorrection = item.PsoID;
            this.IsCorrection = true;
        },

        closeDialogCorrection()
        {
            this.dialogCorrection = false;
        },

        closeToolbar(){
            this.dialogObservation = false; 
            this.$emit('refresh');
        },
        clickInTab(item)
        {
            
        },

        hasObservation(item)
        {
            //let observation = this.typeObservationArray.find(item);
            let observation = this.typeObservationArray.findIndex(el => el == item)
            ServicePostionObservation
            .list({
                TypeObservation: (observation+1),
                PstID: this.parentID
            }, this.$fun.getUserID())
            .then(resp => {
                if(resp.status == 200)
                {
                    let hasObservationPendiente = resp.data.find(el => el.PsoStatus == 1);
                    
                    if(hasObservationPendiente != undefined)
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }
            });
            /* typeObservationArray.forEach((ele, index) => {
                if(ele == item)
                {
                    let filter = {
                        TypeObservation: (index+1),
                        PstID: this.parentID
                    }

                    var countInProcess = false;

                    ServicePostionObservation
                    .list(filter, this.$fun.getUserID())
                    .then(resp => {
                        if(resp.status == 200)
                        {
                            resp.data.forEach(element => {
                                if(element.PsoStatus == 1)
                                {
                                    countInProcess = true;
                                }
                            });
                            
                        }
                    });

                    return countInProcess;
                }
            }); */
        },

        approveObservation(item, PsoStatus)
        {
           
            var message = PsoStatus == 2 ? '¿Seguro de aceptar?' : PsoStatus == 4 ? '¿Seguro de rechazar?' : 'Seguro de corregir';

            if (PsoStatus == 2 && item.Reason != "") {
            this.$fun.alert("El motivo solo se agrega para rechazar una Observación.", "warning")
            return;
            }

            if (PsoStatus == 4 && item.Reason == "") {
            this.$fun.alert("El motivo de rechazo es obligatorio.", "warning")
            return;
            }

            item.PsoStatus = PsoStatus;
            item.UsrUpdateID = this.$fun.getUserID();

            this.$fun.alert(message, "question")
            .then(r => {
                if(r.value)
                {
                    ServicePostionObservation
                    .save(item, this.$fun.getUserID())
                    .then(resp => {
                        if(resp.status == 200)
                        {
                            
                            let message = PsoStatus == 2 ? "Observación Corregida" : "Observación Desaprobada"
                            this.$fun.alert(message, "success");
                            this.$refs.scrudfunciones[0].refresh();
                            this.$refs.scrudreportes[0].refresh();
                            this.$refs.scrudindicadores[0].refresh();
                            this.$refs.scrudconfiguracionacademica[0].refresh();
                            this.$refs.scrudconocimientos[0].refresh();
                            this.$refs.scrudcompetencias[0].refresh();
                            this.$refs.scrudexperiencia[0].refresh();
                            this.listObservation();

                            //this.$emit("refresh")
                        }
                    })
                }
            })
            

        },

        openDialogObservations(typeObservation, nameTypeObservation, row, DocEntry, name)
        {
            this.nameTypeObservation = 'Observaciones - ' + nameTypeObservation;
            this.typeObservation = typeObservation;
            this.dialogObservation = true;
            this.itemDetailSelected = row;
            this.itemDetailSelected.description = name;
            this.itemDetailSelected.descriptionBack = name;
            this.itemDetailSelected.DocEntry = DocEntry;
            this.DocEntry = DocEntry;
            console.log('typeObservation ',typeObservation);
            console.log('nameTypeObservation ',nameTypeObservation);
            console.log('row ',row);
        },

        listObservation()
        {
            this.filterResponsability.PstID = this.parentID;
            this.itemsObservations = [];

            console.log(this.typeObservation );
            if(this.typeObservation == 1)
            {
                let filter = {
                    TypeObservation: this.typeObservation,
                    PstID: this.parentID,
                    PfnID: this.itemDetailSelected.PfnID
                }
                
                ServicePostionObservation
                .listrecord(filter, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        this.itemsObservations = resp.data;   
                        this.itemsObservations.forEach(item => {
                            item.PsoDescription = item.PfnDescription;
                            item.PsoStatus = item.PfnStatus;
                        });
                        console.log(this.itemsObservations);                   
                    }
                })
            }
            if(this.typeObservation == 2)
            {
                let filter = {
                    TypeObservation: this.typeObservation,
                    PstID: this.parentID,
                    RppID: this.itemDetailSelected.RppID

                }
                
                ServicePostionObservation
                .listreportrecord(filter, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        this.itemsObservations = resp.data;   
                        this.itemsObservations.forEach(item => {
                            item.PsoDescription = item.RppDescription;
                            item.PsoStatus = item.RppStatus;
                        });
                        console.log(this.itemsObservations);                   
                    }
                })
            }
            if(this.typeObservation == 3)
            {
                let filter = {
                    TypeObservation: this.typeObservation,
                    PstID: this.parentID,
                    IndID: this.itemDetailSelected.IndID
                }
                
                ServicePostionObservation
                .listindicatorrecord(filter, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        this.itemsObservations = resp.data;   
                        this.itemsObservations.forEach(item => {
                            item.PsoDescription = item.IndDescription;
                            item.PsoStatus = item.IndStatus;
                        });
                        console.log(this.itemsObservations, 'INDICADORES');                   
                    }
                })
            }
            if(this.typeObservation == 4)
            {
                let filter = {
                    TypeObservation: this.typeObservation,
                    PstID: this.parentID,
                    PprID: this.itemDetailSelected.PprID 
                }
                
                ServicePostionObservation
                .listprofilerecord(filter, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        this.itemsObservations = resp.data;   
                        this.itemsObservations.forEach(item => {
                            item.PsoDescription = item.PprDescription;
                            item.PsoStatus = item.PpdStatus;
                        });
                        console.log(this.itemsObservations);                   
                    }
                })
            }
            if(this.typeObservation == 5 || this.typeObservation == 6 || this.typeObservation == 7)
            {
                let filter = {
                    TypeObservation: this.typeObservation,
                    PstID: this.parentID,
                    PprID: this.itemDetailSelected.PprID 
                }
                
                ServicePostionObservation
                .listprofilerecord(filter, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        this.itemsObservations = resp.data;   
                        this.itemsObservations.forEach(item => {
                            item.PsoDescription = item.PprDescription;
                            item.PsoStatus = item.PpdStatus;
                        });
                        console.log(this.itemsObservations);                   
                    }
                })
            }
        },

        saveObservation()
        {
            console.log(this.itemsObservations, this.typeObservation);
            
            this.$fun.alert("¿Seguro de guardar?", "question")
            .then( r => {
                if(r.value)
                {
                    if(this.typeObservation == 1)
                    {
                        SGenPositionRecord.saveFunction(this.itemsObservations, this.$fun.getUserID()).then( r => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Guardado Correctamente", "success");
                            }
                        });
                    }

                    else if(this.typeObservation == 2)
                    {
                        SGenPositionRecord.saveReport(this.itemsObservations, this.$fun.getUserID()).then( r => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Guardado Correctamente", "success");
                            }
                        });
                    }

                    else if(this.typeObservation == 3)
                    {
                        SGenPositionRecord.saveIndicator(this.itemsObservations, this.$fun.getUserID()).then( r => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Guardado Correctamente", "success");
                            }
                        });
                    }
                    else
                    {
                        SGenPositionRecord.saveProfile(this.itemsObservations, this.$fun.getUserID()).then( r => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Guardado Correctamente", "success");
                            }
                        });
                    }
                }
            });
            // this.$fun.alert("¿Seguro de guardar?", "question")
            // .then(r => {
            //     if(r.value)
            //     {
            //         if(this.IsCorrection)
            //         {
            //             this.PsoDescription = this.PsoDescriptionCorrection;
            //         }
            //         if(this.PsoDescription == "")
            //         {
            //             this.$fun.alert("Ingrese observación", "warning");
            //             return;
            //         }

            //         let itemObservation = {
            //             PsoDescription: this.PsoDescription,
            //             UsrCreateID: this.$fun.getUserID(),
            //             TypeObservation: this.typeObservation,
            //             DocEntry : this.DocEntry,
            //             TypeAction : this.TypeAction,
            //             PstID:  this.parentID,
            //             PsoIDCorrection : this.PsoIDCorrection,
            //             PsoUsrIDCorrection : this.$fun.getUserID()
            //         }

            //         console.log('saveee ', itemObservation);
                    
            //         ServicePostionObservation
            //         .save(itemObservation, this.$fun.getUserID())
            //         .then(resp => {
            //             if(resp.status == 200)
            //             {
            //                 this.$fun.alert("Guardado Correctamente", "success");
            //                 this.PsoDescription = '';
            //                 this.listObservation();
            //                 if(this.IsCorrection)
            //                 {
            //                     this.dialogCorrection = false;
            //                     this.PsoIDCorrection = null;
            //                 }
            //                 this.dialogObservation = false;
            //                 this.$refs.scrudfunciones[0].refresh();
            //                 this.$refs.scrudreportes[0].refresh();
            //                 this.$refs.scrudindicadores[0].refresh();
            //                 this.$refs.scrudconfiguracionacademica[0].refresh();
            //                 this.$refs.scrudconocimientos[0].refresh();
            //                 this.$refs.scrudcompetencias[0].refresh();
            //                 this.$refs.scrudexperiencia[0].refresh();
            //                 this.$refs.scrudcondiciones[0].refresh();
            //                 //this.$emit("refresh")
            //                 return;
            //             }
            //         })
            //     }
            // })
        },

        tabSelectedData() {

            if (this.tabSelected == "tab-Funciones") this.filterFuntions = { PstID: this.parentID }
            else if (this.tabSelected == "tab-Reportes") this.filterReport = { PstID: this.parentID }
            else if (this.tabSelected == "tab-Indicadores") this.filterKPI = { PstID: this.parentID }
            else if (this.tabSelected == "tab-Condiciones") { this.filterCondition = { PstID: this.parentID } } else if (this.tabSelected == "tab-Evaluación") this.filterBackground = { PstID: this.parentID }
            else if (this.tabSelected == "tab-Formación_Académica") { this.filterAcademic = { PstID: this.parentID } } else if (this.tabSelected == "tab-Conocimientos") this.filterKnowledge = { PstID: this.parentID }
            else if (this.tabSelected == "tab-Competencias") this.filterCompetition = { PstID: this.parentID }
            else if (this.tabSelected == "tab-Experiencia") this.filterExperience = { PstID: this.parentID }
        },

        changeTab(n) {
            this.tabSelected = n;
            // si es description seleccione funciones
            if (n == 'tab-Descripción') this.tabSelected = "tab-Funciones";
            if (n == 'tab-Perfil') this.tabSelected = "tab-Formación_Académica";
            //si es perfil selecciona academic
            this.tabSelectedData();
        },

        saveAcademic(item) {
            item.PstID = this.parentID;
            item.TypeProfile = 1084;
            if (item.PprQualificationValue == 0) { this.$fun.alert('Seleccione Tipo', 'warning'); return; }
            if (item.PprDescription.trim().length == 0) { this.$fun.alert("Agregue Descripción", 'warning'); return; }
            item.save();
        },

        savePerfilKnowledge(item) {
            item.PstID = this.parentID;
            item.TypeProfile = 1085;
            if (item.PprQualificationValue == 0) { this.$fun.alert('Seleccione Tipo', 'warning'); return; }
            if (item.PprDescription.trim().length == 0) { this.$fun.alert("Agregue Descripción", 'warning'); return; }
            if (item.PprQualificationDescription == 0 || item.PprQualificationDescription == null) { this.$fun.alert('Seleccione Nivel', 'warning'); return; }
            item.save();
        },

        savePerfilCompetition(item) {
            item.PstID = this.parentID;
            item.TypeProfile = 1086;
            if (item.PprQualificationValue == 0) { this.$fun.alert('Seleccione Tipo', 'warning'); return; }
            if (item.PprDescription.trim().length == 0) { this.$fun.alert("Agregue Descripción de Experiencia", 'warning'); return; }
            if (item.PprQualificationDescription == 0 || item.PprQualificationDescription == null) { this.$fun.alert('Seleccione Nivel', 'warning'); return; }
            item.save();
        },

        savePerfilExperience(item) {
            item.PstID = this.parentID;
            item.TypeProfile = 1087;
            item.PprQualificationValue = 1;

            if (item.PprDescription.trim().length == 0) { this.$fun.alert("Agregue Descripción de Experiencia", 'warning'); return; }
            if (item.PprQualificationDescription.trim().length == 0) { this.$fun.alert('Agregue Cantidad de Años de Experiencia', 'warning'); return; }
            item.save();
        },

        saveFunction(item) {
            item.PstID = this.parentID;
            if (item.PfnDescription.trim().length < 1) { this.$fun.alert("Complete Descripción", "warning"); return }
            item.save();
        },

        saveReport(item) {
            item.PstID = this.parentID;
            if (item.RppDescription.trim().length < 1) { this.$fun.alert("Complete Nombre de Reporte", "warning"); return }
            item.save();
        },

        savePerfil(item) {},

        saveCondition(item) {
            console.log(item)
            item.PstID = this.parentID;
            if (item.TypeWorkingCondition == 0) { this.$fun.alert("Seleccione Condición", "warning"); return }
            if (item.PwcDescription.trim().length < 1) { this.$fun.alert("Complete Descripción", "warning"); return }

            item.save();
        },

        saveBackground(item) {
            item.PstID = this.parentID;
            if (item.TypeBackground == 0) { this.$fun.alert("Seleccione Condición", "warning"); return }
            if (item.PbgDescription.trim().length < 1) { this.$fun.alert("Complete Descripción", "warning"); return }
            // if(item.PbgPercentaje.trim().length<1){this.$fun.alert("Complete Descripción","warning"); return}
            item.save();
        },

        saveKPI(item) {
            item.PstID = this.parentID;
            if (item.IndUnitMeasurement == 0) { this.$fun.alert("Falta Unidad de Medición", "warning"); return }

            // if (item.IndOrientation == 0) { this.$fun.alert("Falta Orientación", "warning"); return }
            if (item.IndDescription.trim().length < 1) { this.$fun.alert("Complete Descripción", "warning"); return }
            // if(item.PbgPercentaje.trim().length<1){this.$fun.alert("Complete Descripción","warning"); return}
            item.save();
        },
    },
    watch: {
        parentID() {
            this.tabSelectedData();
            this.listObservation();
        },
        TypeObservation()
        {
            this.listObservation();
        },
        dialogObservation()
        {
            this.listObservation();
        }
    },
    created() {
        this.filterFuntions = { PstID: this.parentID }
    },

    mounted () {
        this.listObservation();
        this.hasObservation();
    },

};
</script>
