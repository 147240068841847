<template>
  <div>
    <v-dialog style="" v-model="dialogOrg" v-if="dialogOrg" persistent width="615" transition="dialog-bottom-transition">
      <v-card style="overflow: hidden !important;">
        <v-toolbar dark style="z-index:9999" elevation="3" dense color="primary">
          <v-toolbar-title dark> Organigrama Sunshine </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-col id="imgcol">
          <div id="img">
            <org @printImg="pdf()" :managerNameView="false" :level="0" :parent="selected" :report="true"></org>
          </div>
        </v-col>
      </v-card>
    </v-dialog>
    

    <v-container class="pt-0 pb-0">
      <v-row>
        <v-col lg="12" class="pb-1">
          <s-crud
            searchInput
            :filter="filterPosition"
            no-border
            height="170"
            pdf
            excel
            @pdf="reportPdf($event)"
            @rowSelected="rowSelected($event)"
            title="Cargos"
            :config="this.config"
            @save="save($event)"
            :add="isAdmin"
            edit
            :remove="isAdmin"
            ref="scrudPositions"
          >
            <template v-slot:filter>
              <div>
                <v-col>
                  <v-row justify="center">
                    <v-col lg="3" class="m">
                      <s-select label="Filtro Fijos" v-model="filterPosition.PstIsPermanent" :items="itemsPermanent" />
                    </v-col>
                    <v-col lg="3" class="s-">
                      <!--v-if="isAdmin"-->
                      <s-select-area 
                        
                        label="Filtro por Área" 
                        v-model="filterPosition.AreID"> 
                      </s-select-area>
                     <!--<s-select-area-allow 
                        v-else
                        v-model="filterPosition.AreID" 
                        :clearable="false"
                        
                      />--> 
                    </v-col>
                    <v-col lg="3" class="s-com">
                      <s-select-definition no-default label="Filtro Críticos" clearable v-model="filterPosition.PstIsBascCritic" :def="1150" />
                    </v-col>
                    <v-col lg="3" class="s-com">
                      <s-select-definition no-default label="Estado" clearable v-model="filterPosition.PstObservation" :def="1397" />
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </template>

            <template scope="props">
              <v-container v-if="props.item != null">
                <v-row justify="center">
                  <v-col lg="8" class="s-col-form">
                    <s-text autofocus v-model="props.item.PstName" label="Nombre" :disabled="!isAdmin"></s-text>
                  </v-col>
                  <v-col lg="4" class="s-col-form">
                    <s-text v-model="props.item.PstAbbreviation" label="Abreviatura" :disabled="!isAdmin"></s-text>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col lg="2" class="s-col-form">
                    <s-text v-model="props.item.PstCodeSunat" label="Código Sunat"></s-text>
                  </v-col>
                  <v-col lg="2" class="s-col-form">
                    <s-select-definition v-model="props.item.TypePosition" :def="1039" :dgr="4" label="Tipo de Cargo"></s-select-definition>
                  </v-col>
                  <v-col lg="2" class="s-col-form">
                    <s-select-area v-model="props.item.AreID" :text="props.item.Area" label="Sub-Área"></s-select-area>
                  </v-col>
                  <v-col lg="2" class="s-col-form">
                    <!-- <s-select
                      v-model="props.item.LbrID"
                      hide-details
                      dense
                      item-value="LbrID"
                      label="Régimen Laboral"
                      outlined
                      item-text="LaborRegimeText"
                      :items="itemsRegime"
                      :disabled="!isAdmin"
                      :add="$fun.isAdmin()"
                    ></s-select> -->
                    <s-select-definition
                    :def="1481"
                    label="Régimen laboral"                   
                    v-model="props.item.TypeLaborRegime"
                    :disabled="!isAdmin"
                    ref="LbrIDRef"
                    :add="$fun.isAdmin()"                   
                  />
                  </v-col>
                  <v-col lg="2" class="s-col-form">
                    <s-select-definition v-model="props.item.PstFunctionalGroup" :def="1517" label="Grupo Funcional" :add="$fun.isAdmin()"></s-select-definition>
                  </v-col>
                  <v-col lg="2" class="s-col-form">
                    <s-select-definition v-model="props.item.PstHierarchicalLevel" :def="1519" label="Nivel Jerárquico" :add="$fun.isAdmin()"></s-select-definition>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col lg="12" class="s-col-form">
                    <s-textarea label="Objetivo" v-model="props.item.PstObjective"></s-textarea>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col lg="2" class="s-col-form">
                    <s-select-definition
                      v-model="props.item.PstStatus"
                      :def="1117"
                      label="Estado de Cargo"
                      :disabled="!isAdmin"
                    ></s-select-definition>
                  </v-col>
                  <v-col lg="3" class="s-col-form">
                    <s-select-definition :def="1150" v-model="props.item.PstIsBascCritic" add label="Nivel Criticidad - Cadena de Suministro"></s-select-definition>
                  </v-col>
                  <v-col lg="3" class="s-col-form">
                    <s-select-definition :def="1150" v-model="props.item.PstIsBascCriticSST" add label="Criticidad SST"></s-select-definition>
                  </v-col>
                  <v-col lg="2" class="s-col-form">
                    <s-switch v-model="props.item.PstIsPermanent" label="Cargo Fijo" :disabled="!isAdmin"></s-switch>
                  </v-col>
                  <v-col lg="2" class="s-col-form">
                    <s-switch v-model="props.item.PstMedical" label="Personal Medico" :disabled="!isAdmin"></s-switch>
                  </v-col>
                </v-row>
              </v-container>
            </template>

            <template v-slot:PstStatus="{ row }">
              <v-chip style="margin:0px" x-small :color="row.PstStatus == 1 ? 'success' : 'error'">
                {{ row.PstStatus == 1 ? "Activo" : "Baja" }}
              </v-chip>
            </template>

            <template v-slot:PstIsPermanent="{ row }">
              <v-simple-checkbox hide-details="" disabled="" style="margin:0px;padding:0px;" :value="row.PstIsPermanent == 1 ? true : false">
              </v-simple-checkbox>
            </template>

            <template v-slot:PstIsBascCritic="{ row }">
              <v-chip
                x-small
                :color="
                  row.PstIsBascCritic == 1 || row.PstIsBascCritic == 0
                    ? 'white'
                    : row.PstIsBascCritic == 2
                    ? 'primary'
                    : row.PstIsBascCritic == 3
                    ? 'warning'
                    : 'error'
                "
                style="margin:0px;"
                >{{
                  row.PstIsBascCritic == 0
                    ? "No Definido"
                    : row.PstIsBascCriticName
                }}
              </v-chip>
            </template>
            <template v-slot:PstIsBascCriticSST="{ row }">
              <v-chip
                x-small
                :color="
                  row.PstIsBascCriticSST == 1 || row.PstIsBascCriticSST == 0
                    ? 'white'
                    : row.PstIsBascCriticSST == 2
                    ? 'primary'
                    : row.PstIsBascCriticSST == 3
                    ? 'warning'
                    : 'error'
                "
                style="margin:0px;"
                >{{
                  row.PstIsBascCriticSST == 0
                    ? "No Definido"
                    : row.PstIsBascCriticSSTName
                }}
              </v-chip>
            </template>

            <template v-slot:PstObservationAmount="{ row }">
              <v-chip
                x-small
                :color="
                  row.PstObservationAmount > 0
                    ? 'warning'
                    : 'success'
                "
                style="margin:0px;"
                >{{
                  row.PstObservationAmount == 0
                    ? "Sin Observaciones"
                    : row.PstObservationsName
                }}
              </v-chip>
            </template>
          </s-crud>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="12" class="pt-0">
          <position-detail v-if="parentID != 0" :parentID="parentID" @refresh="refreshDataTable()" style="padding-top:0px;padding-bottom:0px;"></position-detail>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Org from "@/components/Org/Org";
import html2canvas from "html2canvas";
import PositionDetail from "./PositionDetail";
import Service from "./../../services/General/PositionService";
export default {
  name: "Position",
  components: { PositionDetail, Org },
  mounted() {

    this.filterPosition.UsrID = this.$fun.getUserID();
    this.filterPosition.IsAdmin = this.isAdmin ? 1 : 0

    //this.item.LbrID = 1;
  },
  data: () => ({
    filterPosition: { 
        AreID: null, 
        PstIsBascCritic: null, 
        PstIsPermanent: 1,
        UsrID: null,
        IsAdmin: null,
    },
    itemsRegime: [],
    itemsCritic: [
      { text: "CRÍTICOS", value: 1 },
      { text: "NO CRÍTICOS", value: 0 },
      { text: "TODOS", value: 3 },
    ],

    itemsPermanent: [
      { text: "FIJOS", value: 1 },
      { text: "VARIABLES", value: 0 },
      // {text:'TODOS',value:3}
    ],
    dialogOrg: false,
    selected: [],
    config: {
      model: {
        PstName: "string",
        PstAbbreviation: "string",
        TypePosition: "number",
        PstCodeSunat: "number",
        PstObjective: "string",
        PstIsBascCritic: "boolean",
        PstIsBascCriticSST: "boolean",
        PstMedical: "boolean",
        AreID: "number",
        PstIsPermanent: "boolean",
        PstDeparment: "number",

        UsrCreateID: "number",

        PstID: "ID",
        SecStatus: "boolean",
        PstStatus: "",
        PstObservationAmount: 0,
        PstObservationsName: ""
      },
      title: "Cargos",
      service: Service,
      headers: [
        { text: "ID", value: "PstID" },
        { text: "Cargo", value: "PstName" },
        // { text: 'SUNAT', value: 'PstCodeSunat' },
        { text: "Área", value: "Area" },
        { text: "Departamento", value: "DepartmentText", width: 220 },

        { text: "Nivel Criticidad", value: "PstIsBascCritic"},
        { text: "Criticidad SST", value: "PstIsBascCriticSST"},
        { text: "Estado", value: "PstStatus" },
        { text: "Observación", value: "PstObservationAmount" },
      ],
    },

    parentID: 0,
    isAdmin: false,
  }),
  methods: {
    refreshDataTable()
    {
      this.$refs.scrudPositions.refresh();
    },

    reportPdf(item) {
      if (item != null) {
        this.selected = item;
        this.dialogOrg = true;
      }
    },

    exportImg() {
      console.log('ddddddddd');
      setTimeout(() => {
        html2canvas(document.getElementById("org")).then((canvas) => {
          let enlace = document.createElement("a");
          enlace.download = "Organigrama-SunshineExport.png";
          enlace.href = canvas.toDataURL();
          //  enlace.ImageData();
          enlace.click();
        });
      }, 4000);
    },

    pdf() {
      console.log('ddddddddd');
      setTimeout(() => {
        this.$nextTick().then(() => {
          
          html2canvas(document.getElementById("org")).then((canvas) => {
            canvas.toBlob((blob) => {

              this.dialogOrg = false;

              Service.PDF(this.selected.PstID, blob).then((r) => {
                this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, this.selected.PstName);
              });
            });
          });
        });
      }, 500);
    },

    save(item) {
      if (item.PstName.trim().length <= 0) {
        this.$fun.alert("Complete Nombre de Cargo", "warning");
        return;
      }
      if (item.TypePosition == 0) {
        this.$fun.alert("Seleccione Tipo de cargo", "warning");
        return;
      }
      if (item.AreID == 0) {
        this.$fun.alert("Seleccione Área", "warning");
        return;
      }
      item.PstName = item.PstName.toUpperCase();
      item.PstAbbreviation = item.PstAbbreviation.toUpperCase();
      item.LbrID = this.$refs.LbrIDRef.items.find(x=> x.DedValue == item.TypeLaborRegime).DedHelper;
      console.log("Cargo", item);
      item.save();
    },

    rowSelected(rows) {
      console.log(rows);
      if (rows.length > 0) {
        this.parentID = rows[0].PstID;
      }
    },
  },
  watch: {
    selected() {
      console.log(this.selected);
    },
  },
  created() {
    Service.laborRegimeList().then((r) => {
      console.log(r.data);
      this.itemsRegime = r.data;
    });
    this.isAdmin = this.$fun.getSecurity().IsLevelAdmin;
    console.log(this.isAdmin);
  },
};
</script>

<style></style>
